import { encryptUsingAES256 } from "../../helpers/utils";
const {createSlice} = require("@reduxjs/toolkit");
const {makeHttpRequest} = require("../apiService/apiService");
const {Showtoast} = require("../../helpers/utils");

const initialTaskState = {
    isLoading: false,
    isError: false,
    boqFormData: [],
    };
const getBOQFormDataSlice = createSlice({
    name: "Get BOQ Form Data",
    initialState: initialTaskState,
    reducers: {
        boqFormDataApiRequested(state) {
            state.isLoading = true;
            state.isError = false;
            state.boqFormData = [];
        },
        boqFormDataApiSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.boqFormData = action.payload;
        },
        boqFormDataApiFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.boqFormData = [];
        },
    },
});

export const getBOQFormDataAction = ({ orderId, navigate,setBoqModal }) => {
    return (dispatch) => {
        dispatch(GetBOQFormDataActions.boqFormDataApiRequested());
        let url = `/get-boq-form-data?orderId=${encryptUsingAES256(JSON.stringify(orderId))}`;
        makeHttpRequest({ method: "get", url: url, navigate })
            .then((res) => {
                if (res?.data?.code === 200) {
                    dispatch(
                        GetBOQFormDataActions.boqFormDataApiSuccess(res?.data?.data)
                    );
                    setBoqModal?.(true);
                } else {
                    dispatch(
                        GetBOQFormDataActions.boqFormDataApiFailure(
                            res?.data?.message
                        )
                    );
                    Showtoast(res?.data?.message, "error");
                }
            })
            .catch((err) => {
                if (err?.isUnauthorized) {
                    return;
                }
                dispatch(
                    GetBOQFormDataActions.boqFormDataApiFailure(
                        err?.response?.data?.error
                    )
                );
                Showtoast(err?.response?.data?.error, "error");
            });
    };
}

export const GetBOQFormDataActions = getBOQFormDataSlice.actions;
export default getBOQFormDataSlice.reducer;