import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";
import { encryptUsingAES256 } from "../../helpers/utils";

const initialSyncDataState = {
  isLoading: false,
  isError: false,
  syncData: null,
};

const getSyncDataSlice = createSlice({
  name: "GetSyncData",
  initialState: initialSyncDataState,
  reducers: {
    apiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.syncData = null;
    },

    apiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.syncData = action.payload;
    },

    apiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.syncData = null;
    },
    
    resetSyncData(state) {
      state.isLoading = false;
      state.isError = false;
      state.syncData = null;
    },
  },
});

export const getSyncDataAction = (orderId) => {
  return (dispatch) => {
    dispatch(getSyncDataActions.apiRequested());
    makeHttpRequest({
      method: "get",
      url: `/fetch-sync-data?orderId=${encryptUsingAES256(JSON.stringify(orderId))}`,
    })
      .then((res) => {
        dispatch(getSyncDataActions.apiSuccess(res?.data));
      })
      .catch((err) => {
        dispatch(getSyncDataActions.apiFailure(err));
      });
  };
};

export const resetSyncDataFromComponent = () => (dispatch) => {
  dispatch(getSyncDataActions.resetSyncData());
};

export const getSyncDataActions = getSyncDataSlice.actions;
export default getSyncDataSlice.reducer;
