import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";

const initialOrderState = {
  isLoading: false,
  errMsg: "",
  orderRes: "",
  isOrderUpdated: null,
};

const updateOrderSlice = createSlice({
  name: "Update Order Slice",
  initialState: initialOrderState,
  reducers: {
    updateOrderApiRequested(state) {
      state.isLoading = true;
      state.errMsg = "";
      state.orderRes = "";
      state.isOrderUpdated = null;
    },
    updateOrderApiSuccess(state, action) {
      state.isLoading = false;
      state.errMsg = "";
      state.orderRes = action.payload;
      state.isOrderUpdated = true;
    },
    updateOrderApiFailure(state, action) {
      state.isLoading = false;
      state.errMsg = action.payload;
      state.orderRes = "";
      state.isOrderUpdated = false;
    },
  },
});

export const updateOrderApiAction = (data, navigate, setShow2) => {
  return (dispatch) => {
    dispatch(createOrderActions.updateOrderApiRequested());

    makeHttpRequest({
      method: "put",
      url: `/order`,
      data: data,
      navigate,
      skipEncryption: true
    })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch(
            createOrderActions.updateOrderApiSuccess(res?.data?.message)
          );
          setShow2(true);
        } else {
          dispatch(
            createOrderActions.updateOrderApiFailure(res?.data?.message)
          );
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(createOrderActions.updateOrderApiFailure(err?.message));
      });
  };
};

export const createOrderActions = updateOrderSlice.actions;
export default updateOrderSlice.reducer;
