import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, ShowtoastSuccess, accessKeys, generateRandomKey } from "../../helpers/utils";
import { makeHttpRequest } from "../apiService/apiService";
import secureLocalStorage from "react-secure-storage";

const initialResetPasswordState = {
  data: null,
  isLoading: false,
  isOtpSent: false,
  isError: "",
  isOtpVerified: false,
  isPasswordReset: false,
};

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: initialResetPasswordState,
  reducers: {
    // Reducer for sending otp
    sendOtpInitial(state) {
      state.isLoading = false;
      state.isError = "";
      state.isOtpVerified = false;
      state.isPasswordReset = false;
    },
    sendOtpRequested(state) {
      state.isLoading = true;
      state.isOtpVerified = false;
      state.isPasswordReset = false;
    },

    sendOtpSuccess(state, action) {
      state.data = action.payload;
      state.isLoading = false;
      state.isOtpSent = true;
      state.isError = "";
      state.isOtpVerified = false;
      state.isPasswordReset = false;
    },

    sendOtpFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
      state.isOtpVerified = false;
      state.isPasswordReset = false;
    },

    // Reducer for verify otp
    verifyOtpRequested(state) {
      state.isLoading = true;
      state.isOtpVerified = false;
      state.isError = "";
      state.isPasswordReset = false;
    },

    verifyOtpSuccess(state) {
      state.isLoading = false;
      state.isOtpVerified = true;
      state.isError = "";
      state.isPasswordReset = false;
    },

    verifyOtpFailure(state, action) {
      state.isLoading = false;
      state.isOtpVerified = false;
      state.isError = action.payload;
      state.isPasswordReset = false;
    },

    // Reducer for reset password
    resetPasswordRequested(state) {
      state.isLoading = true;
      state.isPasswordReset = false;
      state.isError = "";
      state.isOtpVerified = false;
    },

    resetPasswordSuccess(state) {
      state.isLoading = false;
      state.isPasswordReset = true;
      state.isError = "";
      state.isOtpVerified = false;
    },

    resetPasswordFailure(state, action) {
      state.isLoading = false;
      state.isPasswordReset = false;
      state.isError = action.payload;
      state.isOtpVerified = false;
    },
  },
});

export const otpActionGet = (data, setShow, setResetPassword, navigate) => {
  return (dispatch) => {
    dispatch(resetPasswordActions.verifyOtpRequested());
    let customKeyValue = generateRandomKey();
    makeHttpRequest({
      method: "post",
      url: `/otp/verify`,
      data: { ...data, customDate: customKeyValue },
      headers: { Authorization: null },
      navigate,
    })
      .then((response) => {
        if (response?.data?.status === "Success") {
          if (response?.data?.data?.customDate != customKeyValue) {
            Showtoast("Something Went Wrong");
            dispatch(resetPasswordActions.verifyOtpFailure(""));
          } else {
            dispatch(resetPasswordActions.verifyOtpSuccess());
            ShowtoastSuccess(response?.data?.message);
            setShow(false);
            setResetPassword(true);

            const loginResponse = secureLocalStorage?.getItem("loginresponse");

            if (loginResponse) {
              secureLocalStorage?.setItem("loginResponse", loginResponse);
              secureLocalStorage?.removeItem("loginresponse");
              const userRoles = JSON.parse(loginResponse)?.navigations;

              if (userRoles?.includes(accessKeys?.viewRoleListing)) {
                navigate("/role-management");
              } else if (userRoles?.includes(accessKeys?.viewUserListing)) {
                navigate("/user-management");
              } else if (userRoles?.includes(accessKeys?.viewTaskListing)) {
                navigate("/order-management");
              } else if (userRoles?.includes(accessKeys?.viewMasterListing)) {
                navigate("/master-data-management");
              }
            }
          }
        } else {
          dispatch(resetPasswordActions.verifyOtpFailure(response?.data?.message));
          Showtoast(response?.data?.message);
        }
      })
      .catch((error) => {
        if (error?.isUnauthorized) {
          return;
        }
        dispatch(resetPasswordActions.verifyOtpFailure(error?.message));
      });
  };
};

export const otpActionPost = (
  data,
  setShow,
  navigate,
  setMinutes,
  setSeconds
) => {
  return (dispatch) => {
    dispatch(resetPasswordActions.sendOtpRequested());
    let customKeyValue = generateRandomKey();
    makeHttpRequest({
      method: "post",
      data: { ...data, customDate: customKeyValue },
      url: `/otp`,
      headers: { Authorization: null },
      navigate,
    })
      .then((response) => {
        if (response?.data?.status === "Success") {
          if (response?.data?.data?.customDate != customKeyValue) {
            Showtoast("Something Went Wrong");
            dispatch(resetPasswordActions.sendOtpFailure(""));
          } else {
            dispatch(resetPasswordActions.sendOtpSuccess(response?.data?.data));
            ShowtoastSuccess(response?.data?.message);
            setShow(true);
            setMinutes(0);
            setSeconds(59);
          }
        } else {
          dispatch(resetPasswordActions.sendOtpFailure(response?.data?.message));
          Showtoast(response?.data?.message);
        }
      })
      .catch((error) => {
        if (error?.isUnauthorized) {
          return;
        }
        dispatch(resetPasswordActions.sendOtpFailure(error?.message));
      });
  };
};

export const resetPasswordAction = (
  data,
  setForgetPassword,
  setResetPassword,
  navigate
) => {
  return (dispatch) => {
    dispatch(resetPasswordActions.resetPasswordRequested());
    let customKeyValue = generateRandomKey();
    makeHttpRequest({
      method: "put",
      url: `/reset-password`,
      data: { ...data, customDate: customKeyValue },
      headers: { Authorization: null },
      navigate,
    })
      .then((response) => {
        if (response?.data?.status === "Success") {
          if (response?.data?.data?.customDate != customKeyValue) {
            Showtoast("Something Went Wrong");
            dispatch(resetPasswordActions.resetPasswordFailure(""));
          } else {
            dispatch(resetPasswordActions.resetPasswordSuccess());
            ShowtoastSuccess(response?.data?.message);
            setForgetPassword(true);
            setResetPassword(false);
          }
        } else {
          dispatch(resetPasswordActions.resetPasswordFailure(response?.data?.message));
          Showtoast(response?.data?.message);
        }
      })
      .catch((error) => {
        if (error?.isUnauthorized) {
          return;
        }
        dispatch(resetPasswordActions.resetPasswordFailure(error?.message));
      });
  };
};

export const resetPasswordActions = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
