import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "fiverse-a82ba.firebaseapp.com",
  projectId: "fiverse-a82ba",
  storageBucket: "fiverse-a82ba.appspot.com",
  messagingSenderId: "374430887224",
  appId: "1:374430887224:web:610c2e0b0010846726e5cd",
  measurementId: "G-Z81TC6MBT9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const generateToken = async () => {
    const permission = await Notification.requestPermission();
    console.log('permission', permission);
    if (permission === "granted") {
        const token = await getToken(messaging, {
            vapidKey: "BD3c3C_zrJCzog5V7xhwfPCn5JSMvdpnQwW4tDMo0N3Pb9rh7HUG-daA5M47V8RpA378yB6J_lCmAgzAY17DTPg"
        })
        console.log('token', token);
    }

}