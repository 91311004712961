import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";
import { encryptUsingAES256 } from "../../helpers/utils";

const initialTaskState = {
  isLoading: false,
  isError: false,
  polygonFormData: null,
};

const getPolygonFormSlice = createSlice({
  name: "Get Polygon Form Data Two",
  initialState: initialTaskState,
  reducers: {
    polygonFormDataApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.polygonFormData = null;
    },
    polygonFormDataApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.polygonFormData = action.payload;
    },
    polygonFormDataApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.polygonFormData = null;
    },
  },
});

export const getPolygonFormAction = ({
  orderId,
  navigate,
  setViewPolygonShow,
}) => {
  return (dispatch) => {
    dispatch(getPolygonFormActions.polygonFormDataApiRequested());
    let url = `/order/form?orderId=${encryptUsingAES256(
      JSON.stringify(orderId)
    )}`;
    makeHttpRequest({ method: "get", url: url, navigate })
      .then((res) => {
        if (res?.data?.code === 200) {
          dispatch(
            getPolygonFormActions.polygonFormDataApiSuccess(res?.data?.data)
          );
          setViewPolygonShow(true);
        } else {
          dispatch(
            getPolygonFormActions.polygonFormDataApiFailure(res?.data?.message)
          );
        
        }
      })
      .catch((err) => {
        dispatch(
          getPolygonFormActions.polygonFormDataApiFailure(
            err?.response?.data?.error
          )
        );
      });
  };
};

export const getPolygonFormActions = getPolygonFormSlice.actions;
export default getPolygonFormSlice.reducer;
