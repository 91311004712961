import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, ShowtoastSuccess, clearSsoLogoutCookies } from "../../helpers/utils";
import { makeHttpRequest } from "../apiService/apiService";
import secureLocalStorage from "react-secure-storage";
import { resetPasswordActions } from "../resetPassword/resetPassword";

const initialLogOutState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  result: "",
};

const logOutSlice = createSlice({
  name: "User logout",
  initialState: initialLogOutState,
  reducers: {
    logOutApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.result = "";
    },
    logOutApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.result = action.payload;
    },
    logOutApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.result = "";
    },
  },
});

export const logOutApiAction = (navigate) => {
  return (dispatch) => {
    dispatch(logOutActions.logOutApiRequested());
    makeHttpRequest({
      method: "delete",
      url: "/log-out",
      navigate,
      headers: {
        platform: "WEB",
        token: `${
          JSON.parse(secureLocalStorage.getItem("loginResponse"))?.bearerToken
        }`,
      },
    })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch(logOutActions.logOutApiSuccess(res?.data?.message));
          clearSsoLogoutCookies();
          let deviceId = localStorage.getItem("deviceId");
          secureLocalStorage.clear();
          localStorage.setItem('deviceId',deviceId);
          navigate("/");
          ShowtoastSuccess(res?.data?.message);
          dispatch(resetPasswordActions.sendOtpInitial());
        } else {
          dispatch(logOutActions.logOutApiFailure(res?.data?.message));
          Showtoast(res?.data?.message, "error");
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(logOutActions.logOutApiFailure(err?.response?.data?.error));
      });
  };
};
export const logOutActions = logOutSlice.actions;
export default logOutSlice.reducer;
