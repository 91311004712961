import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";
import { encryptUsingAES256 } from "../../helpers/utils";

const initialDropDownData = {
  isTeamLoading: false,
  isRoleLoading: false,
  isRoleIdLoading: false,
  isCircleLoading: false,
  isStatusLoading: false,
  isPlanningLeadLoading: false,
  isCompetitorLoading: false,
  isTeamError: false,
  isRoleError: false,
  isStatusError: false,
  isCircleError: false,
  isRoleIdError: false,
  isPlanningLeadError: false,
  isCompetitorError: false,
  teamList: [],
  roleList: [],
  statusList: [],
  circleList: [],
  roleIdList: [],
  planningLeadList: [],
  subPlanningLeadList: [],
  competitorList: [],
};

const getAllDropDownSlice = createSlice({
  name: "Get All Drop Down List",
  initialState: initialDropDownData,
  reducers: {
    // Team List
    teamApiRequested(state) {
      state.isTeamLoading = true;
      state.isTeamError = false;
      state.teamList = [];
    },
    teamApiSuccess(state, action) {
      state.isTeamLoading = false;
      state.isTeamError = false;
      state.teamList = action.payload;
    },
    teamApiFailure(state, action) {
      state.isTeamLoading = false;
      state.isTeamError = true;
      state.teamList = [];
    },

    // Role List
    roleApiRequested(state) {
      state.isRoleLoading = true;
      state.isRoleError = false;
      state.roleList = [];
    },
    roleApiSuccess(state, action) {
      state.isRoleLoading = false;
      state.isRoleError = false;
      state.roleList = action.payload;
    },
    roleApiFailure(state, action) {
      state.isRoleLoading = false;
      state.isRoleError = true;
      state.roleList = [];
    },

    // Circle List
    circleApiRequested(state) {
      state.isCircleLoading = true;
      state.isCircleError = false;
      state.circleList = [];
    },
    circleApiSuccess(state, action) {
      state.isCircleLoading = false;
      state.isCircleError = false;
      state.circleList = action.payload;
    },
    circleApiFailure(state, action) {
      state.isCircleLoading = true;
      state.isCircleError = true;
      state.circleList = [];
    },

    // Role List by id
    roleByIdApiRequested(state) {
      state.isRoleIdLoading = true;
      state.isRoleIdError = false;
      state.roleIdList = [];
    },
    roleByIdApiSuccess(state, action) {
      state.isRoleIdLoading = false;
      state.isRoleError = false;
      state.roleIdList = action.payload;
    },
    roleByIdApiFailure(state, action) {
      state.isRoleIdLoading = false;
      state.isRoleError = true;
      state.roleIdList = [];
    },

    // Planning List
    planningLeadApiRequested(state) {
      state.isPlanningLeadLoading = true;
      state.isPlanningLeadError = false;
      state.planningLeadList = [];
      state.subPlanningLeadList = [];
    },
    planningLeadApiSuccess(state, action) {
      state.isPlanningLeadLoading = false;
      state.isPlanningLeadError = false;
      state.planningLeadList = action.payload;
    },
    planningLeadApiFailure(state, action) {
      state.isPlanningLeadLoading = false;
      state.isPlanningLeadError = true;
      state.planningLeadList = [];
    },

    // SubPlanning List
    subPlanningLeadApiRequested(state) {
      state.isPlanningLeadLoading = true;
      state.isPlanningLeadError = false;
      state.subPlanningLeadList = [];
    },
    subPlanningLeadApiSuccess(state, action) {
      state.isPlanningLeadLoading = false;
      state.isPlanningLeadError = false;
      state.subPlanningLeadList = action.payload;
    },
    subPlanningLeadApiFailure(state, action) {
      state.isPlanningLeadLoading = false;
      state.isPlanningLeadError = true;
      state.subPlanningLeadList = [];
    },

    // Competitor List
    competitorListApiRequested(state) {
      state.isCompetitorLoading = true;
      state.isCompetitorError = false;
      state.competitorList = [];
    },

    competitorListApiSuccess(state, action) {
      state.isCompetitorLoading = false;
      state.isCompetitorError = false;
      state.competitorList = action.payload;
    },

    competitorListApiFailure(state, action) {
      state.isCompetitorLoading = false;
      state.isCompetitorError = true;
      state.competitorList = [];
    },

    // Status List
    statusListApiRequested(state) {
      state.isStatusLoading = true;
      state.isStatusError = false;
      state.statusList = [];
    },

    statusListApiSuccess(state, action) {
      state.isStatusLoading = false;
      state.isStatusError = false;
      state.statusList = action.payload;
    },

    statusListApiFailure(state, action) {
      state.isStatusLoading = false;
      state.isStatusError = true;
      state.statusList = [];
    },
  },
});

export const getStatusListAction = (navigate) => {
  return (dispatch) => {
    dispatch(dropDownListActions.statusListApiRequested());
    makeHttpRequest({ method: "get", url: "/status", navigate })
      .then((res) => {
        if (res && res?.data?.status === "Success") {
          dispatch(dropDownListActions.statusListApiSuccess(res?.data?.data));
        } else {
          dispatch(
            dropDownListActions.statusListApiFailure(res?.data?.message)
          );
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(dropDownListActions.statusListApiFailure(err));
      });
  };
};

export const getTeamListAction = (navigate) => {
  return (dispatch) => {
    dispatch(dropDownListActions.teamApiRequested());
    makeHttpRequest({ method: "get", url: "/team-list", navigate })
      .then((res) => {
        if (res && res?.data?.status === "Success") {
          dispatch(dropDownListActions.teamApiSuccess(res?.data?.data));
        } else {
          dispatch(dropDownListActions.teamApiFailure(res?.data?.message));
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(dropDownListActions.teamApiFailure(err));
      });
  };
};

export const getRoleListAction = (teamId, navigate) => {
  return (dispatch) => {
    dispatch(dropDownListActions.roleApiRequested());
    let url = `/roles-list`;

    if (teamId) {
      url += `?teamId=${encryptUsingAES256(JSON.stringify(teamId))}`;
    }

    makeHttpRequest({ method: "get", url: url, navigate: navigate })
      .then((res) => {
        if (res && res?.data?.status === "Success") {
          dispatch(dropDownListActions.roleApiSuccess(res?.data?.data));
        } else {
          dispatch(dropDownListActions.roleApiFailure(res?.data?.message));
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(dropDownListActions.roleApiFailure(err));
      });
  };
};

export const getCircleListAction = (navigate) => {
  return (dispatch) => {
    dispatch(dropDownListActions.circleApiRequested());

    makeHttpRequest({ method: "get", url: "/circle-list", navigate: navigate })
      .then((res) => {
        if (res && res?.data?.status === "Success") {
          dispatch(dropDownListActions.circleApiSuccess(res?.data?.data));
        } else {
          dispatch(dropDownListActions.circleApiFailure(res?.data?.message));
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(dropDownListActions.circleApiFailure(err));
      });
  };
};

export const getRoleListByTeamId = (id, navigate) => {
  return (dispatch) => {
    dispatch(dropDownListActions.roleByIdApiRequested());

    makeHttpRequest({
      method: "get",
      url: `/role-by-team-id?teamId=${encryptUsingAES256(JSON.stringify(id))}`,
      navigate,
    })
      .then((res) => {
        if (res && res?.data?.status === "Success") {
          dispatch(dropDownListActions.roleByIdApiSuccess(res?.data?.data));
        } else {
          dispatch(dropDownListActions.roleByIdApiFailure(res?.data?.message));
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(dropDownListActions.roleByIdApiFailure(err));
      });
  };
};

export const getPlanningLeadAction = (id, roleCode, userId, navigate) => {
  return (dispatch) => {
    userId ?
      dispatch(dropDownListActions.subPlanningLeadApiRequested()) :
      dispatch(dropDownListActions.planningLeadApiRequested());

    makeHttpRequest({
      method: "get",
      url: `/get-planning-lead-by-circle?roleCode=${encryptUsingAES256(roleCode)}&circleId=${encryptUsingAES256(JSON.stringify(id))}${userId ? ('&userId=' + encryptUsingAES256(JSON.stringify(userId))) : ''}`,
      navigate,
    })
      .then((res) => {
        if (res && res?.data?.status === "Success") {
          userId ?
            dispatch(dropDownListActions.subPlanningLeadApiSuccess(res?.data?.data)) :
            dispatch(dropDownListActions.planningLeadApiSuccess(res?.data?.data));
        } else {
          userId ?
            dispatch(
              dropDownListActions.subPlanningLeadApiFailure(res?.data?.message)
            ) :
            dispatch(
              dropDownListActions.planningLeadApiFailure(res?.data?.message)
            );
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        userId ?
          dispatch(dropDownListActions.subPlanningLeadApiFailure(err)) :
          dispatch(dropDownListActions.planningLeadApiFailure(err));
      });
  };
};

export const getCompetitorListAction = (navigate) => {
  return (dispatch) => {
    dispatch(dropDownListActions.competitorListApiRequested());

    makeHttpRequest({
      method: "get",
      url: "/competitor-operator",
      navigate,
    })
      .then((res) => {
        if (res && res?.data?.status === "Success") {
          dispatch(
            dropDownListActions.competitorListApiSuccess(res?.data?.data)
          );
        } else {
          dispatch(
            dropDownListActions.competitorListApiFailure(res?.data?.message)
          );
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(dropDownListActions.competitorListApiFailure(err));
      });
  };
};

export const dropDownListActions = getAllDropDownSlice.actions;
export default getAllDropDownSlice.reducer;
