import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as assets from "../../assets/index";
import style from "./Header.module.css";
import Backdrop from "@mui/material/Backdrop";
import secureLocalStorage from "react-secure-storage";
import { accessKeys, validateKey } from "../../helpers/utils";
import MyProfile from "../../containers/MyProfile";
import { useDispatch, useSelector } from "react-redux";
import { logOutApiAction } from "../../store/logOut/logOut";
import Loading from "../Loading/Loading";

const NavbarComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [logout, setLogout] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const userRole = JSON.parse(secureLocalStorage.getItem("loginResponse"));
  const loading = useSelector((state) => state?.logOutReducer?.isLoading);

  const handleBackdrop = () => {
    setOpenBackdrop(false);
    setLogout(false);
  };

  const handleLogout = () => {
    dispatch(logOutApiAction(navigate));
  };

  const handleProfile = () => {
    setShowProfile(true);
  };

  return (
    <>
      {loading && <Loading />}
      <Navbar
        onClick={() => {
          if (openBackdrop === true) {
            setOpenBackdrop(false);
          }

          if (logout === true) {
            setLogout(false);
          }
        }}
        expand="lg"
        sticky="top"
        className={`${style.custum_css} px-4`}
      >
        {/* Add padding using Bootstrap utility class px-4 */}
        <Navbar.Brand as={Link} to="/" className={style.airtel_icon_handle}>
          <img
            alt="Logo"
            src={assets?.aiterlLogo}
            width="60"
            height="61"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {validateKey(accessKeys?.viewRoleListing) && (
              <Nav.Link
                className={style.link_width}
                onClick={() => navigate("/role-management")}
              >
                <span
                  className={`${style.header_name} ${location.pathname === "/role-management"
                      ? style.header_name_active
                      : ""
                    }`}
                >
                  Role Management
                </span>
              </Nav.Link>
            )}

            {validateKey(accessKeys?.viewUserListing) && (
              <Nav.Link
                className={style.link_width}
                onClick={() => navigate("/user-management")}
              >
                <span
                  className={`${style.header_name} ${location.pathname === "/user-management"
                      ? style.header_name_active
                      : ""
                    }`}
                >
                  User Management
                </span>
              </Nav.Link>
            )}

            {validateKey(accessKeys?.viewTaskListing) && (
              <Nav.Link
                className={style.link_width}
                onClick={() => navigate("/order-management")}
              >
                <span
                  className={`${style.header_name} ${location.pathname === "/order-management" ||
                      location?.pathname === "/order-locality-management" ||
                      location?.pathname?.includes("/order-management-details") ||
                      location?.pathname?.includes(
                        "/assign-circle-planning-lead"
                      ) ||
                      location?.pathname?.includes("/update-boq-form") ||
                      location?.pathname?.includes("/assign-order-lists")
                      ? style.header_name_active
                      : ""
                    }`}
                >
                  Order Management
                </span>
              </Nav.Link>
            )}

            {validateKey(accessKeys?.viewMasterListing) && (
              <Nav.Link
                className={style.link_width1}
                onClick={() => navigate("/master-data-management")}
              >
                <span
                  className={`${style.header_name} ${location.pathname === "/master-data-management"
                      ? style.header_name_active
                      : ""
                    }`}
                >
                  Master Data Management
                </span>
              </Nav.Link>
            )}

            {validateKey(accessKeys?.FeasibilityModule) && (
              <Nav.Link
                className={style.link_width}
                onClick={() => navigate("/feasibility-module")}
              >
                <span
                  className={`${style.header_name} ${location.pathname === "/feasibility-module"
                    ? style.header_name_active
                    : ""
                    }`}
                >
                  Feasibility Module
                </span>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
        {userRole && <Navbar.Brand
          onClick={() => {
            setLogout(!logout);
            setOpenBackdrop(!openBackdrop);
          }}
          className={style.user_icon_handle}
        >
          <img
            alt="Logo"
            src={assets?.userIcon}
            width="16"
            height="21"
            className="d-inline-block align-top"
          />{" "}
          <span className={style.admin}>{userRole?.userName}</span>
          {logout && (
            <div className={style.logout_div}>
              <div
                className={style.logout_li}
                onClick={handleProfile}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleProfile();
                  }
                }}
              >
                Profile
              </div>
              <div
                onClick={handleLogout}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleProfile();
                  }
                }}
                className={style.logout_li}
              >
                Logout
              </div>
            </div>
          )}
        </Navbar.Brand>}
      </Navbar>

      <MyProfile show={showProfile} setShow={setShowProfile} />

      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: "transparent",
          zIndex: "10 !important",
        }}
        open={openBackdrop}
        onClick={handleBackdrop}
      ></Backdrop>
    </>
  );
};

export default NavbarComponent;
