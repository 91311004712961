import { useLocation } from "react-router";
import NavbarComponent from "./components/Header";
import Routing from "./routes/Routing";
import { useEffect } from "react";
import { generateRandomKey } from "./helpers/utils";
import { generateToken, messaging } from "./notification/firebase";
import { onMessage } from "firebase/messaging";


const App = () => {
  const location = useLocation();
  const isLoginPage =
    location.pathname === "/" ||
    location.pathname === "/page-not-found" ||
    location.pathname === "/server-error";

  useEffect(() => {
    window.scrollTo(0, 0);
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = generateRandomKey();
      localStorage.setItem('deviceId', deviceId);
    }
  }, [location.pathname]);
  // useEffect(()=>{
  //   generateToken();
  // },[])


  useEffect(()=>{
    generateToken();
    onMessage(messaging, (payload) => {
      console.log('token Message received. ', payload);
    });
  },[])

  return (
    <>
      {!isLoginPage && <NavbarComponent />}
      <Routing />
    </>
  );
};
export default App;
