import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import forge from "node-forge";
import CryptoJS from "crypto-js";
import moment from "moment/moment";
import { emojiRegex } from "./constants";

let randomKey = generateRandomKey();

export function Showtoast(msg) {
  toast.dismiss();
  toast.error(`${msg}`, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
}

export function ShowtoastSuccess(msg) {
  toast.success(`${msg}`, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
}

export function generateRandomKey() {
  const buffer = new Uint8Array(8);
  window.crypto.getRandomValues(buffer);
  const hexString = Array.from(buffer)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return hexString;
}

export const getBase64EncryptedRSAData = () => {
  const formattedPublicKey =
    "-----BEGIN PUBLIC KEY-----" +
    process.env.REACT_APP_KKK_RANDOM_STRING +
    "-----END PUBLIC KEY-----";

  const pk = forge.pki.publicKeyFromPem(formattedPublicKey);

  const encryptedData = pk.encrypt(randomKey, "RSAES-PKCS1-V1_5");

  const base64EncryptedData = forge.util.encode64(encryptedData);

  return base64EncryptedData;
};

export const encryptUsingAES256 = (pass) => {
  let _key = CryptoJS.enc.Latin1.parse(randomKey);
  let _iv = CryptoJS.enc.Latin1.parse(randomKey);
  let encrypted = CryptoJS.AES.encrypt(pass, _key, {
    keySize: 16,
    iv: _iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export function Debouncing(fn, delay = 500) {
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(fn.timer);
    fn.timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

export const getRSADecryptedData = (data) => {
  const privateKeyBase64 = process.env.REACT_APP_SSS_RANDOM_STRING;
  const privateKeyPem =
    "-----BEGIN PRIVATE KEY-----\n" +
    privateKeyBase64.match(/.{1,64}/g).join('\n') +
    "\n-----END PRIVATE KEY-----";

  let privateKey;
  try {
    privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
  } catch (error) {
    console.error("Error parsing private key:", error);
    return null;
  }

  try {
    const decodedData = forge.util.decode64(data);
    const decryptedData = privateKey.decrypt(decodedData);
    return decryptedData;
  } catch (error) {
    console.error("Error decrypting data:", error);
    return null;
  }
};

export const decryptUsingAES256 = (encrypt, key, iv) => {
  let _key = CryptoJS.enc.Latin1.parse(key);
  let _iv = CryptoJS.enc.Latin1.parse(iv);
  let decrypted = CryptoJS.AES.decrypt(encrypt, _key, {
    keySize: 16,
    iv: _iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Latin1);
  return decrypted;
};

export const emailValidation = (email) => {
  const username = "[a-zA-Z0-9.\\-_+]+";
  const domain =
    "(?:[a-zA-Z0-9](?:[a-zA-Z0-9\\-]*[a-zA-Z0-9])?\\.)+[a-zA-Z]{2,}";
  const pattern = new RegExp(`^(?=[^@]*[A-Za-z])${username}@${domain}$`, "i");
  return pattern.test(String(email).toLowerCase());
};

export const passwordValidation = (password) => {
  return /^(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/.test(
    password
  );
};
export const oltOdfValidation = (deviceName) => {
  deviceName = deviceName.toUpperCase();
  if (deviceName.length > 3) {
    deviceName = deviceName.slice(0, 3);
  }
  if (!/^[a-zA-Z0-9]+$/.test(deviceName)) {
    deviceName = deviceName.slice(0, -1);
  }
  return deviceName;
}
export const hasAlphanumeric = (oltName) => {
  const alphaRegex = /[a-zA-Z]/;
  const numericRegex = /[0-9]/;
  return alphaRegex.test(oltName) && numericRegex.test(oltName);
}

export const handleInputFieldChange = (e, onChange) => {
  const newValue = e.target.value.replace(emojiRegex, "");
  e.target.value = newValue;
  onChange(e);
}

export const passMatchWithCPass = (pass, cpass) => {
  let value = false;
  if (pass.length === cpass.length && pass === cpass) {
    value = true;
  }
  return value;
};

export const accessKeys = {
  // Role Management
  viewRoleListing: "982S6861M83282NC",
  editRole: "834S1550N10666UL",

  // User Management
  viewUserListing: "147H6449W67426RE",
  createUser: "118E7385J17356WO",
  editUser: "121V5050W61950TV",
  teamFilter: "733A4904K45012UV",
  roleFilter: "805M9144Y28040CK",
  circleFilter: "847F5784H36949EK",
  searchUser: "260T5677A22929QN",

  // Task Management
  viewTaskListing: "343N4640O82054NA",
  createTask: "610D1299K49262TG",
  selfOrder: "390A7705J92159KZ",
  allOrder: "529W9506S19411CR",
  showAction: "773I6443J39504FE",

  // Master Data Management
  viewMasterListing: "246O5550B35961VU",
  createCircle: "363Z5719B58576QJ",
  downloadCircleFile: "947G8552C86807GI",
  searchCircle: "354W2951Y93130NM",
  editCircle: "870K9862P35574WV",

  //Feasibility Module
  FeasibilityModule: "488E7238J71098PC"
};

export const roleAccessKeys = {
  super_Admin: 1,
  central_marketing_head: 2,
  circle_marketing_head: 3,
  circle_marketing_lead: 4,
  central_planning_head: 5,
  circle_planning_head: 6,
  circle_planning_lead: 7,
  surveyor: 8,
  central_finance_head: 9,
  circle_finance_head: 10,
};

export const validateKey = (accessKey) => {
  const accessCodes = JSON.parse(
    secureLocalStorage?.getItem("loginResponse")
  )?.navigations;

  return accessCodes?.includes(accessKey);
};

export const generateKMLFileToCoordinates = (kmlFile) => {
  const uint8Array = new Uint8Array(kmlFile);
  const kmlString = new TextDecoder().decode(uint8Array);
  const parser = new DOMParser();
  const kmlDoc = parser.parseFromString(kmlString, "application/xml");
  let coordinateArraynew = [];
  const placemarks = kmlDoc.querySelectorAll("Placemark");
  placemarks.forEach((placemark) => {
    const coordinatesStr = placemark.querySelector("coordinates").textContent;
    const coordinateArray = coordinatesStr
      .split(" ")
      .map((coord) => coord.trim());
    coordinateArraynew = coordinateArray.map((item) => {
      item = item.split(",");
      return { lat: parseFloat(item[1]), lng: parseFloat(item[0]) };
    });
  });
  coordinateArraynew.pop();
  return coordinateArraynew;
};

export const commonDateFormat = (date) => {
  return moment(date).format("DD MMM YYYY");
};

export const commonTimeFormat = (date) => {
  return moment(date).format("h:mm A");
};

export const commonDateTimeFormat = (date) => {
  return moment(date).format("DD MMM YY, h:mm A");
};

export const commonDateStringFormat = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};
export const commonDateStringFormatEndOfDay = (date) => {
  return moment(date).endOf("day").format("YYYY-MM-DD HH:mm:ss");
};

export const CalculateTotalCount = (tempData) => {
  let totalCount = 0;
  tempData.forEach((item) => {
    totalCount += Number(item.userGivenCount * item.costPerUnit);
  });
  return Number(totalCount).toFixed(2);
};
export const CalculateTotalHomePassCount = (homePassCount, totalCost) => {
  let totalHomePassCount = 0;

  if (Number(homePassCount) === 0 || Number(totalCost) === 0) {
    return totalHomePassCount.toFixed(2);
  }

  totalHomePassCount = totalCost / homePassCount;
  return totalHomePassCount.toFixed(2);
};

export const calculateDistanceByCoordinates = (
  coodinates,
  measurementValue
) => {
  function haversine(lat1, lon1, lat2, lon2) {
    const R = 6371 * measurementValue?.value; // Radius of the Earth in meters

    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in meters

    return distance;
  }

  let totalDistance = 0;
  for (let i = 0; i < coodinates.length - 1; i++) {
    let lat1 = coodinates[i].lat;
    let lon1 = coodinates[i].lng;
    let lat2 = coodinates[i + 1].lat;
    let lon2 = coodinates[i + 1].lng;
    totalDistance += haversine(lat1, lon1, lat2, lon2);
  }
  return totalDistance.toFixed(2) + " " + measurementValue?.unit;
};

export const calculateAreaByCoordinates = (coordinates, measurementOptions) => {
  coordinates.push(coordinates[0]);
  function haversine(lat1, lon1, lat2, lon2) {
    const R = 6371 * measurementOptions?.value; // Radius of the Earth in kilometers

    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // Distance in kilometers

    return distance;
  }

  let totalArea = 0;
  for (let i = 0; i < coordinates.length - 2; i++) {
    let lat1 = coordinates[i].lat;
    let lon1 = coordinates[i].lng;
    let lat2 = coordinates[i + 1].lat;
    let lon2 = coordinates[i + 1].lng;
    let lat3 = coordinates[i + 2].lat;
    let lon3 = coordinates[i + 2].lng;

    // Calculate the distances of the sides of the triangle
    const a = haversine(lat1, lon1, lat2, lon2);
    const b = haversine(lat2, lon2, lat3, lon3);
    const c = haversine(lat3, lon3, lat1, lon1);
    // Use Heron's formula to calculate the area of the triangle
    const s = (a + b + c) / 2;
    const triangleArea = Math.sqrt(s * (s - a) * (s - b) * (s - c));

    totalArea += triangleArea;
  }

  return totalArea.toFixed(5) + " " + measurementOptions?.unit + "²";
};

export const calculateDistanceBetweenCoordinates = (coords1, coords2) => {
  const toRad = angle => (angle * Math.PI) / 180;

  const lat1 = toRad(coords1.lat);
  const lon1 = toRad(coords1.lng);
  const lat2 = toRad(coords2.lat);
  const lon2 = toRad(coords2.lng);

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const R = 6371; 
  const distanceInKm = R * c;

  if (distanceInKm < 1) {
    const distanceInMeters = distanceInKm * 1000;
    return `${distanceInMeters.toFixed(0)}m`;
  } else {
    return `${distanceInKm.toFixed(1)}km`;
  }

};

export const checkTitleLength = (value, limit) => {
  if (value?.length > limit) {
    return value;
  } else return null;
};

export const generateUniqueString = () => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const characterLength = characters.length
  for (let i = 0; i < 16; i++) {
    result += characters.charAt(Math.floor(Math.random() * characterLength))
  }
  return result
}

export const convertToCoordinates = (latlng) => {
  function convertDecimalToDMS(decimal) {
    const degrees = Math.floor(decimal);
    const minutesFloat = (decimal - degrees) * 60;
    const minutes = Math.floor(minutesFloat);
    const seconds = ((minutesFloat - minutes) * 60).toFixed(1);
    return `${degrees}°${minutes}'${seconds}"`;
  }

  function convertLatLongToDMS(latitude, longitude) {
    const latitudeDMS = `${convertDecimalToDMS(latitude)} ${latitude >= 0 ? "N" : "S"
      }`;
    const longitudeDMS = `${convertDecimalToDMS(longitude)} ${longitude >= 0 ? "E" : "W"
      }`;
    return `${latitudeDMS} ${longitudeDMS}`;
  }

  const coordinates = latlng;
  const [latitude, longitude] = coordinates.split(",");

  const formattedCoords = convertLatLongToDMS(
    parseFloat(latitude),
    parseFloat(longitude)
  );
  return formattedCoords;
}

export const clearSsoLogoutCookies = () => {
  if (secureLocalStorage?.getItem("loginBySso")) {
    window.open(`${process.env.REACT_APP_SSO_UAT_URL}logout`, "_blank");
    let deviceId = localStorage.getItem("deviceId");
    secureLocalStorage.clear();
    localStorage.setItem('deviceId', deviceId);
  }
}

export const getOptionsObject = (data) => {
  return data.map((item)=>(
    {
      id: item,
      label: item,
      value: item,
    }
  ))
}
export const getValueObject = (item) => {
  return {
    label: item,
    value: item,
  }
}
export const getSearchLimit = (element) => {
  if(element === 'olt_id'){
    return 1;
  }
  else if(element === 'odf_id'){
    return 4;
  }
  else if(element === 'fat_id'){
    return 7;
  }
  else if(element === 'building_id'){
    return 3;
  }
  else if(element === 'site_id'){
    return 1;
  }
  else{
    return 1;
  }
}

export const hasUserRoleAccess = (teamRoleCode,roleCode) => {
  if(teamRoleCode === "CRML" || teamRoleCode === "CRPL" || teamRoleCode === "ZSM"){
    if(roleCode === "MRPT" || roleCode === "BLPE" || roleCode === "SRVR" || roleCode === "BDM" || roleCode === "XFE"){
      return false;
    }
    else return true;
  }
  else 
  return true;
}